import { Component, Input } from '@angular/core';
import { Device, DeviceMeasurementByType, OperationalHourPerStatus, OperationalTypes } from '../../model/device.model';
import { StackedBarDataInput } from '../../../../shared/component/stacked-bar/stacked-bar.component';
import { SharedDeviceFileService } from '../../../device-files/service/shared-device-files.service';
import { map } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

export enum OperationalTypesColorMap {
  'ACTIVE'= 'rgb(78, 198, 51)',
  'IDLE' = 'rgb(0, 114, 188)',
  'WAITING_FOR_USER' = 'rgb(250, 196, 21)',
  'OFFLINE' = '#fff',
  'MALFUNCTIONAL' = '#fff',
}

@Component({
  selector: 'app-device-counters',
  templateUrl: './device-counters.component.html',
  styleUrls: ['./device-counters.component.scss']
})
export class DeviceCountersComponent {

  private _device: Device | undefined;
  private _filetype = 'device_manually_requested_settings';
  private _scope = 'generic';

  protected readonly Math = Math;

  public deviceOperationalHours: Array<OperationalHourPerStatus> | undefined;
  public measurementsByType: Array<DeviceMeasurementByType> | undefined;
  public stackedBarData: Array<StackedBarDataInput> | undefined;
  public lastBackup: Date | undefined;
  public pageSize = 5;

  @Input()
  set device(device: Device | undefined) {
    if (!device) return;
    this._device = device;

    this.sharedDeviceFileService.getFiles(this._device.id, this._filetype, this._scope)
      .pipe(
        map(files => files.map(file => ({ ...file, lastModifiedAt: new Date(file.lastModifiedAt) }))),
        map(files => files.sort((a, b) => a.lastModifiedAt.getTime() - b.lastModifiedAt.getTime())))
      .subscribe(files => this.lastBackup = files[0]?.lastModifiedAt);

    this.deviceOperationalHours = this._device.statistics.operationalHoursPerStatus;
    this.measurementsByType = this._device.statistics.measurements?.measurementsByType
      .filter(measurement =>
        measurement.type === 'Vickers' ||
        measurement.type === 'Rockwell' ||
        measurement.type === 'Knoop' ||
        measurement.type.startsWith('Brinell')
      );
    this.stackedBarData = this.mapToStackedBarData(this.deviceOperationalHours);
  }

  get device(): Device | undefined {
    return this._device;
  }

  constructor(
    private sharedDeviceFileService: SharedDeviceFileService,
    private translateService: TranslocoService
  ) {}

  private mapToStackedBarData(operationalHours: Array<OperationalHourPerStatus> | undefined): Array<StackedBarDataInput> {
    if (!operationalHours) return [];
    return operationalHours
      .filter(val => val.type !== OperationalTypes.OFFLINE && val.type !== OperationalTypes.MALFUNCTIONAL)
      .map(val => ({
        label: `${val.value}H`,
        value: val.value,
        legendTitle: this.translateService.translate(val.type.toLowerCase()),
        backgroundColor: OperationalTypesColorMap[val.type],
        color: val.type === OperationalTypes.IDLE ? '#fff' : '#000'
      }));
  }
}
