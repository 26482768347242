import { Observable } from 'rxjs';

export enum DeviceVendor {
  // Carbolite = 'CARBOLITE',
  Eltra = 'ELTRA',
  // Microtrac = 'MICROTRAC',
  QATM = 'QATM',
  // Retsch = 'RETSCH',
}

export const DeviceVendor2LabelMapping: Record<DeviceVendor, string> = {
  // [DeviceVendor.Carbolite]: 'Carbolite',
  [DeviceVendor.Eltra]: 'Eltra',
  // [DeviceVendor.Microtrac]: 'Microtrac',
  [DeviceVendor.QATM]: 'QATM',
  // [DeviceVendor.Retsch]: 'Retsch',
};

export function matchDeviceVendorByValue(value: string): DeviceVendor | undefined {
  if (!value) {
    return;
  }
  const idx = Object.values(DeviceVendor).indexOf(value as unknown as DeviceVendor);
  const key = Object.keys(DeviceVendor)[idx] as keyof typeof DeviceVendor;
  return DeviceVendor[key];
}

export interface ProgressAbsolute {
  currentValue: number;
  maximumValue: number;
}

export interface ProgressTemporal {
  startedAt: Date;
  estimatedSecondsRemaining: number;
}

export enum DeviceConnectionStatusType {
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED',
}

export interface DeviceConnectionStatus {
  type: DeviceConnectionStatusType;
  lastUpdatedAt?: Date;
}

export enum DeviceOperationalStatusType {
  Initializing = 'INITIALIZING',
  Idle = 'IDLE',
  Active = 'ACTIVE',
  WaitingForUser = 'WAITING_FOR_USER',
  Malfunctional = 'MALFUNCTIONAL',
  NotOperational = 'NOT_OPERATIONAL',
}

export interface DeviceOperationalStatus {
  type: DeviceOperationalStatusType;
  message: string;
  code?: string;
  progressPercentage?: number;
  progressAbsolute?: ProgressAbsolute;
  progressTemporal?: ProgressTemporal;
  lastUpdatedAt?: Date;
}

export interface DeviceStatus {
  connection: DeviceConnectionStatus;
  operational?: DeviceOperationalStatus;
}

export enum DeviceModelType {
  Product = 'PRODUCT',
  Customized = 'CUSTOMIZED',
}

export interface DeviceModelIdentification {
  id: string;
  version?: string;
}

export interface DeviceModelReference {
  vendor: DeviceVendor;
  id: string;
  version?: string;
}

export interface DeviceModel {
  type: DeviceModelType;
  identification: DeviceModelIdentification;
  baseModelIdentification?: DeviceModelIdentification;
  equipmentFeatures?: string[];
}

export interface DeviceNotificationSummaryAvailableNotificationsSummary {
  info: number;
  warning: number;
  error: number;
  critical: number;
}

export interface DeviceNotificationSummary {
  available: DeviceNotificationSummaryAvailableNotificationsSummary;
}

export interface DeviceVirtualLaboratory {
  id: string;
  name: string;
}

export interface DeviceFirmware {
  key: string;
  version: string;
}

export interface OperationalHourPerStatus {
  type: OperationalTypes;
  value: number;
}

export enum OperationalTypes {
  'ACTIVE'= 'ACTIVE',
  'IDLE' = 'IDLE',
  'OFFLINE' = 'OFFLINE',
  'WAITING_FOR_USER' = 'WAITING_FOR_USER',
  'MALFUNCTIONAL' = 'MALFUNCTIONAL',
}

export interface DeviceMeasurementByType {
  type: string;
  value: number;
}

export interface DeviceMeasurements {
  measurementCount: number;
  measurementsByType: Array<DeviceMeasurementByType>;
}

export interface DeviceStatistics {
  operationalHours: number;
  operationalHoursPerStatus: Array<OperationalHourPerStatus>;
  measurements: DeviceMeasurements;
}

export interface DeviceFile {
  id: string;
  name: string;
  contentType: string;
  contentLength: number;
  eTag: string;
  createdAt: Date;
  lastModifiedAt: Date;
}

export enum DeviceNotificationLevel {
  Info = 'INFO',
  Warning = 'WARNING',
  Error = 'ERROR',
  Critical = 'CRITICAL',
}

export enum DeviceNotificationPriority {
  VeryLow = 'VERY_LOW',
  Low = 'LOW',
  Normal = 'NORMAL',
  High = 'HIGH',
  VeryHigh = 'VERY_HIGH',
}

export interface DeviceNotification {
  id: string;
  level: DeviceNotificationLevel;
  priority: DeviceNotificationPriority;
  summary: string;
  text?: string;
  reportedAt: Date;
}

export enum DeviceJournalItemType {
  DeviceStatus = 'DEVICE_STATUS',
  ProcessStatus = 'PROCESS_STATUS',
  SoftwareStatus = 'SOFTWARE_STATUS',
  FirmwareStatus = 'FIRMWARE_STATUS',
}

export enum DeviceJournalItemLevel {
  Info = 'INFO',
  Warning = 'WARNING',
  Error = 'ERROR',
  Critical = 'CRITICAL',
}

export enum DeviceJournalItemPriority {
  VeryLow = 'VERY_LOW',
  Low = 'LOW',
  Normal = 'NORMAL',
  High = 'HIGH',
  VeryHigh = 'VERY_HIGH',
}

export interface DeviceJournalItem {
  id: string;
  type: DeviceJournalItemType;
  level: DeviceJournalItemLevel;
  priority: DeviceJournalItemPriority;
  code: number;
  summary: string;
  text: string;
  reportedAt: Date;
}

export interface DeviceJournalFilterCriteria {
  from?: Date;
  until?: Date;
  types: DeviceJournalItemType[];
  levels: DeviceJournalItemLevel[];
  codes: number[];
}

export enum DeviceRemoteControlRequestResponseStatus {
  Success = 'SUCCESS',
  Failure = 'FAILURE',
  Requested = 'REQUESTED',
}

export interface DeviceRemoteControlRequestItem {
  id: string;
  requestType: string;
  requestPayload: string;
  responseStatus?: DeviceRemoteControlRequestResponseStatus;
  responsePayload?: string;
  respondedAt?: Date;
  requestedAt: Date;
}

export interface DeviceRemoteControlRequestJournalFilterCriteria {
  from?: Date;
  until?: Date;
  type?: string;
  status: DeviceRemoteControlRequestResponseStatus[];
}

export interface DevicePlatformFeatureAccessibleSetting {
  accessible: boolean;
}

export interface DevicePlatformFeatureSettings<T> {
  backups: DevicePlatformFeatureAccessibleSetting;
  logs: DevicePlatformFeatureAccessibleSetting;
  journal: DevicePlatformFeatureAccessibleSetting;
  generic: T;
}

export interface DevicePlatformSettings<T> {
  features: DevicePlatformFeatureSettings<T>;
}

export function createDeviceModelReference(deviceLike: DeviceLike): DeviceModelReference {
  return {
    vendor: deviceLike.vendor,
    id: deviceLike.model.baseModelIdentification?.id || deviceLike.model.identification.id,
    version: deviceLike.model.baseModelIdentification?.version || deviceLike.model.identification.version,
  };
}

export interface DeviceLike {
  id: string;
  virtualLaboratory: DeviceVirtualLaboratory;
  name: string;
  description: string;
  vendor: DeviceVendor;
  type: string;
  model: DeviceModel;
  serialNumber: string;
  firmwares: DeviceFirmware[];
  statistics: DeviceStatistics;
  status: DeviceStatus;
  genericProperties: any;
  reportedPropertiesUpdatedAt?: Date;
}

export interface DeviceSummary extends DeviceLike {
  notificationSummary: DeviceNotificationSummary;
}

export interface Device extends DeviceLike {
  platformSettings: DevicePlatformSettings<string[]>;
}

export interface DeviceAction {
  key: string;
  label: string;
  action: () => Observable<void>;
}

export interface DeviceTokenRequest {
  virtualLaboratoryId: string;
  activationKey?: string;
  deviceReference: {
    vendor: string;
    serialNumber: string;
  };
}

export enum PrepareDeviceRegistrationResult {
  Success,
  NotAuthorized,
  DoesNotExist,
  UnprocessableRequest,
}

export interface DeviceToken {
  token: string;
  preparedDeviceId: string;
  tokenExpiresAt: Date;
}

export interface PrepareDeviceRegistrationResponse {
  token?: DeviceToken;
  resultStatus: PrepareDeviceRegistrationResult;
}

export enum UnregisterDeviceResult {
  Success,
  NotAuthorized,
  DoesNotExist,
  UnprocessableRequest,
}
